<template>
  <div id="drawer-app">
    <v-btn icon style="position: absolute; z-index: 1; left: 10px"
      ><v-icon @click="drawer = !drawer"
        >mdi-menu</v-icon
      ></v-btn
    >
    <v-navigation-drawer
      id="nav-draw"
      app
      v-model="drawer"
      :mini-variant="miniVariant"
    >
      <v-list dense nav class="py-0">
        <v-list-item class="py-8 mb-6">
          <router-link to="/">
            <img src="../../assets/logo.png" />
          </router-link>
        </v-list-item>

        <v-list-item>
            <v-list-item-content class="menu-item">
              <v-list-item-title class="font-weight-bold menu-title">CRM</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="link in CRMLinks" :key="link.title" link class="crm-item">
          <router-link :to="link.to" active-class="active" class="flex">
            <v-list-item-icon class="ma-2 ml-0">
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="menu-item">
              <v-list-item-title color>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>

        <v-list-item>
            <v-list-item-content class="menu-item">
              <v-list-item-title class="font-weight-bold menu-title">Sharethis</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="link in shareThisLinks" :key="link.title" link class="sharethis-item">
          <router-link :to="link.to" active-class="active" class="flex">
            <v-list-item-icon class="ma-2 ml-0">
              <div class="pa-3 menu-circle rounded-circle d-inline-block"></div>
            </v-list-item-icon>

            <v-list-item-content class="menu-item">
              <v-list-item-title color>{{ link.title }}</v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>

        <v-list-item
          variant="plain"
          class="flex items-center w-full"
          style="position: absolute; bottom: 0"
          @click="DrawerMenu()"
        >
          <v-list-item-icon class="mr-4 icon-item">
            <v-icon>{{ toggleMenuIcon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Toggle sidebar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "AppSideBar",
  data() {
    return {
      toggleMenuIcon: "mdi-format-indent-decrease",
      drawer: true,
      shareThisLinks: [
        {
          color: "#94d227",
          to: "/categories-insights",
          title: "Audience Distribution",
          icon: "mdi-view-stream-outline",
        },
        {
          color: "#94d227",
          to: "/event-insights",
          title: "Category Insight Index",
          icon: "mdi-decagram-outline",
        },
        {
          color: "#94d227",
          to: "/data-overview",
          title: "ShareThis Insight",
          icon: "mdi-decagram-outline",
        },
        {
          color: "#94d227",
          to: "/activation",
          title: "Activation",
          icon: "mdi-decagram-outline",
        },
        // {
        //   color: "#94d227",
        //   to: "/target-categories",
        //   title: "Target Categories",
        //   icon: "mdi-decagram-outline",
        // },
        // {
        //   color: "#94d227",
        //   to: "/customers-events",
        //   title: "Prospect Activation",
        //   icon: "mdi-account-multiple-outline",
        // },
      ],
       CRMLinks: [
        {
          color: "#109CF1",
          to: "/",
          title: "CRM Data Overview",
          icon: "mdi-view-stream-outline",
        },
        {
          color: "#109CF1",
          to: "/industry-category",
          title: "CRM Category Selection",
          icon: "mdi-view-dashboard-outline",
        },
        {
          color: "#109CF1",
          to: "/crm-filtering",
          title: "CRM Segmentation",
          icon: "mdi-view-stream-outline",
        },
         {
          color: "#109CF1",
          to: "/tier-processing",
          title: "Segments Preview",
          icon: "mdi-view-dashboard-outline",
        },
      ],
      color: "primary",
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: true,
    };
  },
  methods: {
    DrawerMenu() {
      this.miniVariant = !this.miniVariant;
      this.toggleMenuIcon = this.miniVariant
        ? "mdi-format-indent-increase"
        : "mdi-format-indent-decrease";
    },
  },
};
</script>

<style lang="scss" scoped>
#nav-draw::v-deep {
  .menu-title{
    font-size: 16px !important;
  }
  box-shadow: 2px 0px 5px 1px rgb(0 0 0 / 8%);
  .v-list-item .v-list-item__title {
    color: #334d6e !important;
  }
  .crm-item .exact-active.active * {
    color: #109CF1 !important;
  }
  .sharethis-item .exact-active.active * {
    color: #94d227 !important;
  }
    .v-list-item .v-list-item__title {
    color: black !important;
  }
  .v-list-item .exact-active.active *:not(.v-icon) {
    font-size: 16px !important;
  }
  .menu-circle {
    background: #e5e5e5 !important;
    width: 5px !important;
    height: 5px !important;
    padding: 8px !important;
  }
  .exact-active.active .menu-circle {
    background: #94d227 !important;
    width: 10px !important;
    height: 10px !important;
    padding: 12px !important;
  }
  .icon-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .v-list-item__icon{
    justify-content: center;
    align-items: center;
  }
}
</style> 
