<template>
  <div id="app" class="px-12">
    <v-container fluid>
      <AppSideBar />
      <AppContent />
    </v-container>
  </div>
</template>

<script>
import AppContent from "./dashboard/AppContent.vue";
import AppSideBar from "./dashboard/AppSideBar.vue";
export default {
  components: { AppContent, AppSideBar },
  name: "SharethisLayout",
  data: () => ({
    //
  }),
};
</script>
