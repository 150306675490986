<template>
  <v-main class="mt-8" id="app-content">
    <v-row>
      <v-col>
        <router-view />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "AppContent",
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
#app-content::v-deep {
  span.v-btn__content {
    font-weight: 500 !important;
  }
  #sharethis-loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .v-card {
    border: 2px solid #80808070;
    box-shadow: none !important;
  }
  .btns.color-white {
    color: white !important;
    text-transform: capitalize !important;
    padding: 12px 35px !important;
  }
}
</style>
